// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Env$Showside from "./utils/Env.bs.js";
import * as Shopify$Showside from "./utils/Shopify.bs.js";
import * as Caml_js_exceptions from "../../../node_modules/rescript/lib/es6/caml_js_exceptions.js";
import * as EmbedTypes$Showside from "./types/EmbedTypes.bs.js";
import * as CriticalCSS$Showside from "./utils/CriticalCSS.bs.js";
import * as Webapi__Dom__Element from "../../../node_modules/rescript-webapi/lib/es6_global/src/Webapi/Dom/Webapi__Dom__Element.bs.js";
import * as Configuration$Showside from "./types/Configuration.bs.js";
import * as ConfigIdGetter$Showside from "./ConfigIdGetter.bs.js";
async function initSmartnav(index, element) {
  EmbedTypes$Showside.Attributes.autoAssignNamespaces(element);
  var attributes = EmbedTypes$Showside.Attributes.fromElement(element);
  var configId = await ConfigIdGetter$Showside.getConfigId(undefined, undefined, attributes, /* Inline */1);
  var configId$1;
  if (configId !== undefined) {
    configId$1 = configId;
  } else {
    try {
      var config = await Shopify$Showside.Config.load(Env$Showside.apiHostnameProduction);
      configId$1 = config.id;
    } catch (raw_e) {
      var e = Caml_js_exceptions.internalToOCamlException(raw_e);
      console.error("[Smart Nav] Failed to load Smart Nav config:", e);
      configId$1 = undefined;
    }
  }
  if (configId$1 !== undefined) {
    return Configuration$Showside.preloadConfig(true, configId$1);
  }
}
function getElements(param) {
  return Belt_Array.keepMap(Belt_Array.map(Array.prototype.slice.call(document.querySelectorAll("#maker-smartnav-inline")), Webapi__Dom__Element.ofNode), function (node) {
    return node;
  });
}
function init(param) {
  var elements = getElements(undefined);
  if (elements.length !== 0) {
    Belt_Array.mapWithIndex(elements, initSmartnav);
    CriticalCSS$Showside.insertCSS(undefined, undefined);
    import('./types/ConfigurationDecoder.bs.js');
    import('./components/SmartNavVideo.bs.js');
    import('./utils/PictureInPicture2.bs.js');
    import('./utils/Slider.bs.js');
    import('./pages/QuickViewOverlay.bs.js');
    return import('./InlineEntrypoint.bs.js').then(entrypoint => {
      entrypoint.init();
      window.SmartNavInline = entrypoint;
      if (!window.SmartNav) {
        window.SmartNav = window.SmartNavInline;
      }
    });
  } else {
    console.warn("Could not initialize SmartNav: no element found");
    return;
  }
}
export { initSmartnav, getElements, init };
/* Shopify-Showside Not a pure module */